/*
 * Side Bar - 데이터 관리
 */

import { IconBottle, IconListDetails, IconPinned } from '@tabler/icons';
import { Part } from '../config';

// ==============================|| DATA MENU ITEMS ||============================== //

const data = {
  id: 'data',
  title: '',
  type: 'group',
  children: [
    {
      id: 'dataTab',
      title: '데이터 관리',
      type: 'collapse',
      children: [
        {
          id: 'dataAlcoholManage',
          title: '주류관리',
          type: 'item',
          url: '/data/wine',
          icon: IconBottle,
          breadcrumbs: true
        },
        {
          id: 'dataLocationPinManage',
          title: '위치 핀 관리',
          type: 'item',
          url: '/data/pin',
          icon: IconPinned,
          breadcrumbs: true,
          part: [Part.Admin, Part.SubAdmin]
        },
        {
          id: 'dataMessageTemplateManage',
          title: '메세지 템플릿 관리',
          type: 'item',
          url: '/data/message-template',
          icon: IconListDetails,
          breadcrumbs: true,
          part: [Part.Admin, Part.SubAdmin]
        }
      ]
    }
  ]
};

export default data;
