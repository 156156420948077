import Loadable from '../ui-component/Loadable';
import { lazy } from 'react';
import AuthGuard from '../utils/route-guard/AuthGuard';
import MainLayout from '../layout/MainLayout';
import { PartGuard } from '../utils/route-guard/v2';
import { Part } from '../config';

const WineList = Loadable(lazy(() => import('views/data/wine/WineList')));
const WineCreate = Loadable(lazy(() => import('views/data/wine/WineCreate')));
const WineUpdate = Loadable(lazy(() => import('views/data/wine/WineUpdate')));

// New
const PdataList = Loadable(lazy(() => import('views/data/pdata/PdataList')));
const PdataDetail = Loadable(lazy(() => import('views/data/pdata/PdataDetail')));
const PdataCreate = Loadable(lazy(() => import('views/data/pdata/PdataCreate')));

/**
 * pdata 페이지 라우트
 * 접근 가능한 part: [ADMIN, SUB, WINEDATA, DATACHECKER, OBSERVER]
 *
 * @authors 이재일<leeji@wineone.io>
 */
const PdataRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      // pdata 목록
      path: '/data/wine',
      element: <WineList />
    },
    {
      // pdata 등록
      path: '/data/wine/create',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <WineCreate />
        </PartGuard>
      )
    },
    {
      // pdata 수정
      path: '/data/wine/:id',
      element: <WineUpdate />
    },
    {
      // pdata 목록 new
      path: '/data/pdata',
      element: <PdataList />
    },
    {
      // pdata 상세 new
      path: '/data/pdata/:pdata_id/detail',
      element: <PdataDetail />
    },
    {
      // pdata 등록 new
      path: '/data/pdata/create',
      element: <PdataCreate />
    }
  ]
};

export default PdataRoutes;
